/* eslint-disable */
import { Icon } from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  addDoc,
  collection,
  orderBy,
  setDoc,
  where,
  deleteDoc,
  doc,
  query,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { firestore } from "services/firebase";
import { v4 as uuidv4 } from "uuid";
import { IoMdEye, IoMdTrash } from "react-icons/io";

import ReactQuill, { Quill } from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import "react-quill/dist/quill.bubble.css"; // ES6
import "react-quill/dist/quill.core.css"; // ES6
import ImageResize from "quill-image-resize-module-react";
import quillEmoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register("modules/ImageResize", ImageResize);
Quill.register(
  {
    "formats/emoji": EmojiBlot,
    "modules/emoji-shortname": ShortNameEmoji,
    "modules/emoji-toolbar": ToolbarEmoji,
    "modules/emoji-textarea": TextAreaEmoji,
  },
  true
);

export default function Content() {
  const collectionname = "content";
  const topiccollectionname = "topics";
  const subtopiccollectionname = "subtopics";

  const ref = collection(firestore, collectionname);
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubTopics] = useState([]);
  const [data, setData] = useState();
  const [isUpdate, setisUpdate] = useState(false);
  const [quillvalue, setQuillValue] = useState("");
  const history = useHistory();

  const [detail, setDetail] = useState({
    topicid: "",
    subtopicid: "",
    ordseq: 0,
    heading: "",
  });
  const [docId, setdocId] = useState("");
  let reactQuillRef = React.createRef();

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      let data = new FormData();
      data.append("file", file);
      const link = await uploadImage(data)
        .then((result) => {
          return result.body[0].location;
        })
        .catch((e) => {});
      const range = await quillRef.selection.savedRange;
      quillRef.insertEmbed(range.index, "image", link);
    };
  };

  const formats = [
    "header",
    "list",
    "size",
    "indent",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "script",
    "clean",
    "align",
    "background",
    "direction",
    "link",
    "link",
    "image",
    "color",
    "font",
    "emoji",
    "width",
    "style",
    "float",
    "display",
    "margin",
    "raw-html",
  ];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],

          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],

          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["link"],
          // ["image"],
          ["emoji"],

          ["clean"],
        ],
        // handlers: {
        //   image: imageHandler,
        // },
      },

      "emoji-toolbar": true,
      "emoji-shortname": true,
      ImageResize: {
        modules: ["Resize"],
        parchment: Quill.import("parchment"),
      },
    }),
    []
  );

  useEffect(() => {
    GetAllTopics();
    GetAllSubTopics();
  }, []);

  useEffect(() => {
    if (subtopics && subtopics.length > 0) {
      GetAllContents();
    }
  }, [subtopics]);

  async function GetAllTopics() {
    const data = await query(
      collection(firestore, topiccollectionname),
      orderBy("ordseq", "asc")
    );
    onSnapshot(data, (querySnapshot) => {
      const topics = [];

      querySnapshot.forEach((doc) => {
        let data = {};
        data.topicname = doc.data().topicname;
        data.docid = doc.id;
        topics.push(data);
      });
      setTopics(topics);
    });
  }

  async function GetAllSubTopics() {
    const data = await query(
      collection(firestore, subtopiccollectionname),
      orderBy("created", "asc")
    );
    onSnapshot(data, (querySnapshot) => {
      const subtopicsdata = [];
      querySnapshot.forEach((doc) => {
        let data = {};
        data.subtopicname = doc.data().subtopicname;
        data.topicid = doc.data().topicid;
        data.docid = doc.id;
        subtopicsdata.push(data);
      });
      setSubTopics(subtopicsdata);
    });
  }

  async function GetAllContents() {
    const data = await query(
      collection(firestore, collectionname),
      orderBy("created", "asc")
    );
    onSnapshot(data, (querySnapshot) => {
      const contents = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.docid = doc.id;
        // debugger;
        data.topicname =
          topics &&
          topics.filter((x) => x && x.docid && x.docid === data.topicid)
            .length > 0
            ? topics.filter((x) => x.docid == data.topicid)[0].topicname
            : "";
        data.subtopicname =
          subtopics &&
          subtopics.filter((x) => x.docid == data.subtopicid).length > 0
            ? subtopics.filter((x) => x.docid == data.subtopicid)[0]
                .subtopicname
            : "";
        contents.push(data);
      });
      setData(contents);
    });
  }

  // async function GetTopicNameByTopicId(topicid) {
  //   debugger;
  //   let topicname;
  //   const topicRefDoc = doc(firestore, topiccollectionname, topicid);
  //   await getDoc(topicRefDoc)
  //     .then((snapshot) => {
  //       topicname = snapshot.data().topicname;
  //     })
  //     .catch((e) => console.log(e));
  //   return topicname;
  // }

  //save details to database
  async function SaveDetailsToDb() {
    if (
      detail &&
      detail.topicid.length > 0 &&
      detail.subtopicid.length > 0 &&
      detail.heading.length > 0 &&
      quillvalue &&
      quillvalue.length > 0
    ) {
      let data = {
        uuid: uuidv4(),
        topicid: detail.topicid,
        subtopicid: detail.subtopicid,
        ordseq: detail.ordseq ? parseInt(detail.ordseq) : 0,
        heading: detail.heading,
        content: quillvalue,
        created: new Date().toISOString(),
      };

      try {
        addDoc(ref, data);
        setDetail({
          // topicid: "",
          // subtopicid: "",
          ordseq: 0,
          heading: "",
        });
        setQuillValue("");
        alert("Data added successfully");
      } catch (err) {
        console.log(err);
      }
    }
  }

  //update row Data
  async function UpdateRowData(item) {
    setisUpdate(true);
    setDetail({
      ...detail,
      topicid: item.topicid,
      ordseq: item.ordseq ? parseInt(item.ordseq) : 0,
      subtopicid: item.subtopicid,
      heading: item.heading,
    });
    setQuillValue(item.content);
    setdocId(item.docid);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  //update data from database
  async function UpdateDataFromDb(e) {
    e.preventDefault();
    const docRef = doc(firestore, collectionname, docId);

    const updatedata = await {
      topicid: detail.topicid,
      subtopicid: detail.subtopicid,
      heading: detail.heading,
      ordseq: detail.ordseq ? parseInt(detail.ordseq) : 0,
      content: quillvalue,
      updateddate: new Date().toISOString(),
    };

    await setDoc(docRef, updatedata, { merge: true }).then(
      alert("Data changed successfully")
    );

    setisUpdate(false);
    setDetail({
      // topicid: "",
      // subtopicid: "",
      ordseq: 0,
      heading: "",
    });
    setQuillValue("");
    alert("Data updated successfully");
  }

  //delete data from db
  async function DeleteDataFromDb(item) {
    if (confirm("Are you sure you want to delete?")) {
      const docRef = doc(firestore, collectionname, item.docid);

      await deleteDoc(docRef)
        .then(() => {
          alert("Data Deleted Successfully");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <>
      <div className="card">
        <div class="card-header">
          <h1>{isUpdate ? "Update" : "Add"} Sub Topic</h1>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col col-lg-6 col-md-6 col-sm-12 mb-2">
              <div className="form-group">
                <select
                  className="form-control"
                  onChange={(e) =>
                    setDetail({ ...detail, topicid: e.target.value })
                  }
                  value={detail.topicid}
                >
                  <option value="">Select Topic</option>
                  {topics &&
                    topics.length > 0 &&
                    topics.map((item, index) => {
                      return (
                        <option value={item.docid}>{item.topicname}</option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col col-lg-6 col-md-6 col-sm-12 mb-2">
              <div className="form-group">
                <select
                  className="form-control"
                  onChange={(e) =>
                    setDetail({ ...detail, subtopicid: e.target.value })
                  }
                  value={detail.subtopicid}
                >
                  <option value="">Select Sub-Topic</option>
                  {subtopics &&
                    subtopics.length > 0 &&
                    subtopics
                      .filter((x) => x.topicid == detail.topicid)
                      .map((item, index) => {
                        return (
                          <option value={item.docid}>
                            {item.subtopicname}
                          </option>
                        );
                      })}
                </select>
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 mb-2">
              <div className="form-group">
                <input
                  type="number"
                  value={detail.ordseq}
                  className="form-control"
                  onChange={(e) =>
                    setDetail({ ...detail, ordseq: e.target.value })
                  }
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-lg-11 col-md-11 col-sm-12 mb-2">
              <div className="form-group">
                <input
                  type="text"
                  value={detail.heading}
                  className="form-control"
                  onChange={(e) =>
                    setDetail({ ...detail, heading: e.target.value })
                  }
                  placeholder="Enter Heading"
                />
              </div>
            </div>
            <div
              className="col-lg-12 col-md-12 col-sm-12 mb-2"
              style={{ height: "300px" }}
            >
              <ReactQuill
                ref={(el) => {
                  reactQuillRef = el;
                }}
                placeholder="Write Something"
                theme="snow"
                modules={modules}
                formats={formats}
                value={quillvalue}
                bounds={"editor-outer-class article-des-height"}
                style={{ height: "70%" }}
                // className="form-control text h-50"
                id="floatingTextarea2"
                name="description"
                onChange={setQuillValue}
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
              <div className="form-group">
                {isUpdate ? (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={UpdateDataFromDb}
                    >
                      Update
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        setisUpdate(false);
                        setDetail({
                          // topicid: "",
                          // subtopicid: "",
                          ordseq: 0,
                          heading: "",
                        });
                        setQuillValue("");
                      }}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button className="btn btn-success" onClick={SaveDetailsToDb}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div
            className="table-responsive"
            style={{ maxHeight: "350px", overflowY: "auto" }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>TOPIC(SUB TOPIC)</th>
                  <th>HEADING</th>
                  <th>SEQ</th>
                  <th>UPDATE</th>
                  <th>DELETE</th>
                  <th>PREVIEW</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => {
                    return (
                      <tr key={item}>
                        <td>
                          {item.topicname}({item.subtopicname})
                        </td>
                        <td>{item.heading}</td>
                        <td>{item.ordseq}</td>
                        <td>
                          <button
                            className="btn btn-warning"
                            onClick={(e) => UpdateRowData(item)}
                          >
                            <Icon
                              as={MdEdit}
                              width="20px"
                              height="20px"
                              color="inherit"
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => DeleteDataFromDb(item)}
                          >
                            <Icon
                              as={IoMdTrash}
                              width="20px"
                              height="20px"
                              color="inherit"
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-primary"
                            onClick={(e) =>
                              history.push("/admin/view?docid=" + item.docid)
                            }
                          >
                            <Icon
                              as={IoMdEye}
                              width="20px"
                              height="20px"
                              color="inherit"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
