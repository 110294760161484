import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  // addDoc,
  // collection,
  // orderBy,
  // setDoc,
  // where,
  // deleteDoc,
  doc,
  // query,
  // onSnapshot,
  getDoc,
} from "firebase/firestore";
import { firestore } from "services/firebase";
// import { v4 as uuidv4 } from "uuid";

const View = () => {
  const [params, setParams] = useState(null);
  const [content, setContent] = useState(null);
  const history = useHistory();
  const collectionname = "content";

  useEffect(() => {
    if (history) {
      setHistoryParams();
    }
  }, [history]);

  useEffect(() => {
    if (params && params.docid) {
      GetContentById(params.docid);
    }
  }, [params && params.docid]);

  async function setHistoryParams() {
    let data = {};
    history.location.search
      .replace("?", "")
      .split("&")
      .forEach((element) => {
        let paramdata = element.split("=");
        data[paramdata[0]] = paramdata[1];
      });
    setParams(data);
  }

  async function GetContentById(docid) {
    const docRef = doc(firestore, collectionname, docid);
    await getDoc(docRef)
      .then((snapshot) => {
        setContent(snapshot.data());
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <div className="card">
        <div class="card-body">
          <h1 className="h1">{content && content.heading}</h1>
          <hr />
          {content && (
            <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
          )}
        </div>
      </div>
    </>
  );
};

export default View;
