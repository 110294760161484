export const columnsTopicsDevelopment = [
  {
    Header: "TOPIC NAME",
    accessor: "topicname",
  },
  {
    Header: "UPDATE",
    accessor: "updatetopicid",
  },
  {
    Header: "DELETE",
    accessor: "deletetopicid",
  },
];
