/* eslint-disable */
import { Icon } from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import React, { useMemo, useState, useEffect } from "react";
import {
  addDoc,
  collection,
  orderBy,
  setDoc,
  getCountFromServer,
  deleteDoc,
  doc,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "services/firebase";
import { v4 as uuidv4 } from "uuid";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { IoMdTrash } from "react-icons/io";

export default function TopicsTable(props) {
  const collectionname = "topics";
  const ref = collection(firestore, collectionname);
  const [data, setData] = useState();
  const [isUpdate, setisUpdate] = useState(false);
  const [detail, setDetail] = useState("");
  const [ordseq, setOrderSeq] = useState(0);
  const [docId, setdocId] = useState("");
  const [subtopiccount, setSubtopicCount] = useState(0);

  useEffect(() => {
    GetAllTopics();
  }, []);

  async function GetAllTopics() {
    const data = await query(
      collection(firestore, collectionname),
      orderBy("ordseq", "asc")
    );
    onSnapshot(data, (querySnapshot) => {
      const databaseInfo = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.docid = doc.id;
        databaseInfo.push(data);
      });
      setData(databaseInfo);
    });
  }

  //save details to database
  async function SaveDetailsToDb() {
    if (detail && detail.length > 0) {
      let data = {
        uuid: uuidv4(),
        topicname: detail,
        ordseq: ordseq ? parseInt(ordseq) : 0,
        created: new Date().toISOString(),
      };

      try {
        addDoc(ref, data);
        setDetail("");
        setOrderSeq(0);
      } catch (err) {
        console.log(err);
      }
    }
  }

  //update row Data
  async function UpdateRowData(item) {
    setisUpdate(true);
    setDetail(item.topicname);
    setOrderSeq(item.ordseq);
    setdocId(item.docid);
  }

  //update data from database
  async function UpdateDataFromDb(e) {
    e.preventDefault();
    const docRef = doc(firestore, collectionname, docId);

    const updatedata = await {
      topicname: detail,
      ordseq: ordseq ? parseInt(ordseq) : 0,
      updateddate: new Date().toISOString(),
    };

    await setDoc(docRef, updatedata, { merge: true }).then(
      alert("Data Updated Successfully")
    );

    setisUpdate(false);
    setDetail("");
    setOrderSeq(0);
  }

  //delete data from db
  async function DeleteDataFromDb(item) {
    if (confirm("Are you sure you want to delete?")) {
      const data = await query(
        collection(firestore, "subtopics"),
        where("topicid", "==", item.docid)
      );
      onSnapshot(data, async (querySnapshot) => {
        const databaseInfo = [];

        querySnapshot.forEach((doc) => {
          let data = doc.data();
          if (data.topicid == item.docid) {
            databaseInfo.push(doc.data());
          }
        });

        if (databaseInfo.length > 0) {
          alert(
            "Subtopics contain this topic, please delete the subtopic first, then try again."
          );
        } else {
          const docRef = doc(firestore, collectionname, item.docid);

          await deleteDoc(docRef)
            .then(() => {
              alert(`Data deleted successfully.`);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  }

  return (
    <>
      <div className="card">
        <div class="card-header">
          <h1>{isUpdate ? "Update" : "Add"} Topic</h1>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Topic"
                  value={detail}
                  onChange={(e) => setDetail(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12">
              <div className="form-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter OrdSeq"
                  value={ordseq}
                  onChange={(e) => setOrderSeq(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="form-group">
                {isUpdate ? (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={UpdateDataFromDb}
                    >
                      Update
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        setisUpdate(false);
                        setDetail("");
                        setOrderSeq(0);
                      }}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button className="btn btn-success" onClick={SaveDetailsToDb}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div
            className="table-responsive"
            style={{ maxHeight: "350px", overflowY: "auto" }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>TOPIC NAME</th>
                  <th>SEQ</th>
                  <th>UPDATE</th>
                  <th>DELETE</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => {
                    return (
                      <tr key={item}>
                        <td>{item.topicname}</td>
                        <td>{item.ordseq}</td>
                        <td>
                          <button
                            className="btn btn-warning"
                            onClick={(e) => UpdateRowData(item)}
                          >
                            <Icon
                              as={MdEdit}
                              width="20px"
                              height="20px"
                              color="inherit"
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => DeleteDataFromDb(item)}
                          >
                            <Icon
                              as={IoMdTrash}
                              width="20px"
                              height="20px"
                              color="inherit"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
