import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCMsCZT8fAm9QIRADbqwqYyeomlVxl69po",
  authDomain: "amalemukhtasar.firebaseapp.com",
  projectId: "amalemukhtasar",
  storageBucket: "amalemukhtasar.appspot.com",
  messagingSenderId: "136353637493",
  appId: "1:136353637493:web:07675bc7bbc5bbe597443c",
  measurementId: "G-23MC50JXH3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const firestore = getFirestore(app);
