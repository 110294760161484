/* eslint-disable */
import { Icon } from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import React, { useMemo, useState, useEffect } from "react";
import {
  addDoc,
  collection,
  orderBy,
  setDoc,
  deleteDoc,
  doc,
  where,
  query,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { firestore } from "services/firebase";
import { v4 as uuidv4 } from "uuid";
import { IoMdTrash } from "react-icons/io";

export default function SubTopicsTable() {
  const topiccollectionname = "topics";
  const collectionname = "subtopics";
  const ref = collection(firestore, collectionname);
  const [topics, setTopics] = useState([]);
  const [data, setData] = useState();
  const [isUpdate, setisUpdate] = useState(false);
  const [detail, setDetail] = useState({
    topicid: "",
    ordseq: 0,
    subtopicname: "",
  });
  const [docId, setdocId] = useState("");

  useEffect(() => {
    GetAllTopics();
  }, []);

  useEffect(() => {
    if (topics && topics.length > 0) {
      debugger;
      GetAllSubTopics();
    }
  }, [topics]);

  async function GetAllTopics() {
    const data = await query(
      collection(firestore, topiccollectionname),
      orderBy("ordseq", "asc")
    );
    onSnapshot(data, (querySnapshot) => {
      const topics = [];

      querySnapshot.forEach((doc) => {
        let data = {};
        data.topicname = doc.data().topicname;
        data.docid = doc.id;
        topics.push(data);
      });
      setTopics(topics);
    });
  }

  async function GetAllSubTopics() {
    const data = await query(
      collection(firestore, collectionname),
      orderBy("ordseq", "asc")
    );
    onSnapshot(data, (querySnapshot) => {
      const subtopics = [];
      querySnapshot.forEach(async (document) => {
        let data = document.data();
        data.docid = document.id;
        debugger;
        data.topicname =
          topics &&
          topics.filter((x) => x && x.docid && x.docid === data.topicid)
            .length > 0
            ? topics.filter((x) => x && x.docid && x.docid === data.topicid)[0]
                .topicname
            : "";
        subtopics.push(data);
      });
      setData(subtopics);
    });
  }

  // async function GetTopicNameByTopicId(topicid) {
  //   debugger;
  //   let topicname;
  //   const topicRefDoc = doc(firestore, topiccollectionname, topicid);
  //   await getDoc(topicRefDoc)
  //     .then((snapshot) => {
  //       topicname = snapshot.data().topicname;
  //     })
  //     .catch((e) => console.log(e));
  //   return topicname;
  // }

  //save details to database
  async function SaveDetailsToDb() {
    if (detail && detail.topicid.length > 0 && detail.subtopicname.length > 0) {
      let data = {
        uuid: uuidv4(),
        topicid: detail.topicid,
        subtopicname: detail.subtopicname,
        ordseq: detail.ordseq ? parseInt(detail.ordseq) : 0,
        created: new Date().toISOString(),
      };

      try {
        addDoc(ref, data);
        setDetail({
          topicid: "",
          ordseq: 0,
          subtopicname: "",
        });
        alert("Data Added Successfully");
      } catch (err) {
        console.log(err);
      }
    }
  }

  //update row Data
  async function UpdateRowData(item) {
    setisUpdate(true);
    setDetail({
      ...detail,
      topicid: item.topicid,
      ordseq: item.ordseq,
      subtopicname: item.subtopicname,
    });
    setdocId(item.docid);
  }

  //update data from database
  async function UpdateDataFromDb(e) {
    e.preventDefault();
    const docRef = doc(firestore, collectionname, docId);

    const updatedata = await {
      topicid: detail.topicid,
      subtopicname: detail.subtopicname,
      ordseq: detail.ordseq ? parseInt(detail.ordseq) : 0,
      updateddate: new Date().toISOString(),
    };

    await setDoc(docRef, updatedata, { merge: true }).then(
      alert("Data Updated Successfully")
    );

    setisUpdate(false);
    setDetail({
      topicid: "",
      ordseq: 0,
      subtopicname: "",
    });
  }

  //delete data from db
  async function DeleteDataFromDb(item) {
    if (confirm("Are you sure you want to delete?")) {
      debugger;
      const data = await query(
        collection(firestore, "content"),
        where("subtopicid", "==", item.docid)
      );
      onSnapshot(data, async (querySnapshot) => {
        const databaseInfo = [];

        querySnapshot.forEach((doc) => {
          let data = doc.data();
          if (data.subtopicid == item.docid) {
            databaseInfo.push(doc.data());
          }
        });

        if (databaseInfo.length > 0) {
          alert(
            "Content contain this topic, please delete the content first, then try again."
          );
        } else {
          const docRef = doc(firestore, collectionname, item.docid);

          await deleteDoc(docRef)
            .then(() => {
              alert("Data Deleted Successfully");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  }

  return (
    <>
      <div className="card">
        <div class="card-header">
          <h1>{isUpdate ? "Update" : "Add"} Sub Topic</h1>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="form-group">
                <select
                  className="form-control"
                  onChange={(e) =>
                    setDetail({ ...detail, topicid: e.target.value })
                  }
                  value={detail.topicid}
                >
                  <option value="">Select Topic</option>
                  {topics &&
                    topics.length > 0 &&
                    topics.map((item, index) => {
                      return (
                        <option value={item.docid}>{item.topicname}</option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter SubTopic"
                  value={detail.subtopicname}
                  onChange={(e) =>
                    setDetail({ ...detail, subtopicname: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12">
              <div className="form-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder=""
                  value={detail.ordseq}
                  onChange={(e) =>
                    setDetail({ ...detail, ordseq: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group">
                {isUpdate ? (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={UpdateDataFromDb}
                    >
                      Update
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        setisUpdate(false);
                        setDetail({ topicid: "", ordseq: 0, subtopicname: "" });
                      }}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button className="btn btn-success" onClick={SaveDetailsToDb}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div
            className="table-responsive"
            style={{ maxHeight: "350px", overflowY: "auto" }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>TOPIC NAME</th>
                  <th>SUB TOPIC NAME</th>
                  <th>SEQ</th>
                  <th>UPDATE</th>
                  <th>DELETE</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => {
                    return (
                      <tr key={item}>
                        <td>{item.topicname}</td>
                        <td>{item.subtopicname}</td>
                        <td>{item.ordseq}</td>
                        <td>
                          <button
                            className="btn btn-warning"
                            onClick={(e) => UpdateRowData(item)}
                          >
                            <Icon
                              as={MdEdit}
                              width="20px"
                              height="20px"
                              color="inherit"
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => DeleteDataFromDb(item)}
                          >
                            <Icon
                              as={IoMdTrash}
                              width="20px"
                              height="20px"
                              color="inherit"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
