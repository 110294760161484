import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  // MdPerson,
  MdHome,
  MdLock,
  // MdOutlineShoppingCart,
  MdBook,
  MdList,
  MdPages,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
// import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import Topics from "views/admin/topics";
import SubTopics from "views/admin/subtopics";
import Content from "views/admin/content";
import ContentView from "views/admin/content/view";
// import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import { IoMdEye } from "react-icons/io";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Topics",
    layout: "/admin",
    icon: <Icon as={MdBook} width="20px" height="20px" color="inherit" />,
    path: "/topics",
    component: Topics,
  },
  {
    name: "Sub Topics",
    layout: "/admin",
    icon: <Icon as={MdList} width="20px" height="20px" color="inherit" />,
    path: "/subtopics",
    component: SubTopics,
  },
  {
    name: "Content",
    layout: "/admin",
    icon: <Icon as={MdPages} width="20px" height="20px" color="inherit" />,
    path: "/content",
    component: Content,
  },
  {
    name: "View",
    layout: "/admin",
    icon: <Icon as={IoMdEye} width="20px" height="20px" color="inherit" />,
    path: "/view",
    component: ContentView,
  },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },

  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: Profile,
  // },
  {
    name: "Sign Out",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: RTL,
  // },
];

export default routes;
