import { Box, SimpleGrid } from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  // addDoc,
  collection,
  // orderBy,
  // setDoc,
  // where,
  // deleteDoc,
  // doc,
  // query,
  // onSnapshot,
  // getDoc,
  getCountFromServer,
} from "firebase/firestore";
import { firestore } from "services/firebase";

export default function UserReports() {
  const collectionname = "content";
  const topiccollectionname = "topics";
  const subtopiccollectionname = "subtopics";
  const history = useHistory();

  const [menu, setMenu] = React.useState([
    {
      id: 1,
      name: "Topic",
      count: 0,
      routes: "/admin/topics",
    },
    {
      id: 2,
      name: "Sub Topic",
      count: 0,
      routes: "/admin/subtopics",
    },
    {
      id: 3,
      name: "Content",
      count: 0,
      routes: "/admin/content",
    },
  ]);
  React.useEffect(() => {
    GetTopicCount();
    GetSubTopicCount();
    GetContentCount();
  });

  const GetTopicCount = async () => {
    let items = [...menu];
    let ref = collection(firestore, topiccollectionname);
    await getCountFromServer(ref).then((snapshot) => {
      let itemindex = items.indexOf(menu.filter((x) => x.id === 1)[0]);
      let item = items[itemindex];
      item.count = snapshot.data().count;
      items[itemindex] = item;
      setMenu(items);
    });
  };
  const GetSubTopicCount = async () => {
    let items = [...menu];
    let ref = collection(firestore, subtopiccollectionname);
    await getCountFromServer(ref).then((snapshot) => {
      debugger;
      let itemindex = items.indexOf(menu.filter((x) => x.id === 2)[0]);
      let item = items[itemindex];
      item.count = snapshot.data().count;
      items[itemindex] = item;
      setMenu(items);
    });
  };
  const GetContentCount = async () => {
    let items = [...menu];
    let ref = collection(firestore, collectionname);
    await getCountFromServer(ref).then((snapshot) => {
      debugger;
      let itemindex = items.indexOf(menu.filter((x) => x.id === 3)[0]);
      let item = items[itemindex];
      item.count = snapshot.data().count;
      items[itemindex] = item;
      setMenu(items);
    });
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        {menu &&
          menu.length > 0 &&
          menu.map((menu, index) => {
            return (
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => history.push(menu.routes)}
              >
                <MiniStatistics name={menu.name} value={menu.count} />
              </div>
            );
          })}
      </SimpleGrid>
    </Box>
  );
}
